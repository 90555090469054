import { LocationCursorIconSvg } from "icons/navigation/LocationCursorIcon";
import { useEffect, useMemo, useRef } from "react";
import { LocationSearchPlace } from "services/mapService";

export function useRouteDestination(
  routeDestination: LocationSearchPlace | null
) {
  const routeSourceDestinationGroupRef = useRef<H.map.Group | null>(null);

  const groupObjects = useRef<H.map.Object[]>([]);

  const locationCursorIcon = useMemo(
    () =>
      new H.map.Icon(LocationCursorIconSvg(), {
        anchor: new H.math.Point(20, 40),
        crossOrigin: "anonymous",
        hitArea: new H.map.HitArea(H.map.HitArea.ShapeType.NONE),
      }),
    []
  );

  const setGroupRef = (ref: H.map.Group) =>
    (routeSourceDestinationGroupRef.current = ref);

  useEffect(() => {
    if (!routeSourceDestinationGroupRef.current) {
      return;
    }

    routeSourceDestinationGroupRef.current.removeObjects(groupObjects.current);

    groupObjects.current = [];

    if (!routeDestination) {
      return;
    }

    const locationMarker = new H.map.Marker(routeDestination, {
      icon: locationCursorIcon,
      data: {},
    });

    const addedObject =
      routeSourceDestinationGroupRef.current.addObject(locationMarker);

    groupObjects.current.push(addedObject);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    locationCursorIcon,
    routeDestination,
    routeSourceDestinationGroupRef.current,
  ]);

  return [setGroupRef];
}
