import "@here/maps-api-for-javascript/bin/mapsjs-ui.css";
import "@here/maps-api-for-javascript/bin/mapsjs.bundle";
import { useBooleanState, useTicketDetail } from "common/hooks";
import { MobeaModal } from "common/modal";
import { BottomSlider } from "common/navigation";
import { MapOfflineOverlay } from "common/network/MapOfflineOverlay";
import { MapViewPage } from "common/page/MapViewPage";
import {
  LocationError,
  MapInitPayload,
  useCenteredMap,
  useLocationUpdates,
  useMapInitializer,
  useProviderDataMarkers,
  useReverseGeocode,
  useRouting,
} from "maps";
import { LocateUserButton, MapButtonGroup } from "maps/buttons";
import { useSearchLocationAndProviders } from "maps/effects/useSearchLocationAndProviders";
import { LocationAccessDeniedDialog } from "maps/LocationAccessDeniedDialog";
import { useRadiusCircle } from "pages/map/hooks/useRadiusCircle";
import { MobitTutorial } from "pages/mobit/detail/MobitTutorial";
import {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { LocationCoords, ProviderData } from "services/mapService";
import {
  checkIsMobitBikeAvailable,
  endRide as callEndRide,
  findBike,
  MobitNearbyBike,
  pauseRide,
} from "services/mobitService";
import {
  mobitParkingZonesAcknowledgeAction,
  MobitTravelPass,
} from "state/actions";
import {
  ApiErrors,
  MOBIT_DEFAULT_DURATION,
  Routes,
  TravelPassProvider,
} from "utils";
import { AppColors } from "utils/colors";
import { MobitErrorDialogs } from "../errors/MobitErrorDialogs";
import {
  LocationBoundingBox,
  useBikeInsideEfences,
  useNearbyBikes,
} from "../hooks";
import { MobitReportBikePage } from "../report/MobitReportBikePage";
import { ScanCodePage } from "../scan/ScanCodePage";
import { MobitUnlockBikePage } from "../unlock/MobitUnlockBike";
import {
  CURRENT_MARKER_MIN_ZOOM,
  E_FENCES_MIN_ZOOM,
  INITIAL_MAP_ZOOM,
} from "./constants";
import { MobitRideControls } from "./controls/MobitRideControls";
import { useCurrentBike, useEfences } from "./effects";
import { MobitSelectedBikeSvgIcon } from "./icons";
import "./MobitMap.scss";
import { EndRideDialog, ParkingZoneModal, PauseRideDialog } from "./modal";

type MobitMapIntent =
  // start ride with the new bike (first time or after ended ride)
  | "start"
  // pause ongoing ride with current bike
  | "pause"
  // resume previously paused ride with the same bike
  | "resume"
  // managing ride in progress - check bike lock to know actual bike status
  // resolved as either "pause" or "resume" after getting lock status
  | "unknown";

const H = window.H;

const MobitSelectedBikeIcon = new H.map.DomIcon(MobitSelectedBikeSvgIcon);

const MAP_TOP_BOTTOM_PADDING = 100;
const SEARCH_RADIUS = 3000;

enum MobitUnlockPage {
  NONE,
  SCAN,
  ENTER,
}

export interface MobitMapProps {
  initialIntent: "start" | "unknown"; // nothing more we can deduct from ticket status
}

export function MobitMap({ initialIntent }: MobitMapProps): ReactElement {
  const history = useHistory();

  const { t } = useTranslation();

  const dispatch = useDispatch();

  const {
    id = "",
    bikeCode = "",
    rideId = "",
  } = useParams<{
    id: string;
    bikeCode?: string;
    rideId?: string;
  }>();

  const locale = useSelector((state) => state.user.language);

  const mobitParkingZonesAcknowledged = useSelector(
    (state) => state.onboarding.mobitParkingZonesAcknowledged
  );

  const [userLocation, setUserLocationGroup, locationError] =
    useLocationUpdates();

  const [
    locationErrorDialogVisible,
    showLocationErrorDialog,
    hideLocationErrorDialog,
  ] = useBooleanState();

  // mutable references so they are accessible in hooks
  const currentBikeMarkersGroupRef = useRef<H.map.Group | null>(null);
  const [sliderOpen, setSliderOpen] = useState(true);

  const clusteringProviderRef = useRef<H.clustering.Provider | null>(null);

  // if we have ride in progress we don't know if bike is closed or opened so have to get lock state from API
  const [intent, setIntent] = useState<MobitMapIntent>(initialIntent);

  const [boundingBox, setBoundingBox] = useState<LocationBoundingBox | null>(
    null
  );

  const [selectedBike, setSelectedBike] = useState<
    (MobitNearbyBike & { errored?: boolean }) | null
  >(null);

  const [parkBikeCoords, setParkBikeCoords] = useState<LocationCoords | null>(
    null
  );

  const [pausingRide, setPausingRide] = useState(false);

  const [endingRide, setEndingRide] = useState(false);

  const [searchLocation, setSearchLocation] = useState<LocationCoords | null>(
    null
  );

  const [efencesGroupRef] = useEfences(boundingBox);

  const radiusGroupRef = useRef<H.map.Group | null>(null);

  const [mapRef, mapClassRef, onInit, behaviourRef, platformRef] =
    useMapInitializer(locale, INITIAL_MAP_ZOOM);

  const [
    setSearchLocationMapReferences,
    renderLocationSearchField,
    locationSearchError,
  ] = useSearchLocationAndProviders({
    providers: null,
    loadingProviders: false,
    userLocation,
    searchLocation: intent !== "pause" ? searchLocation : null,
    setSearchLocation,
    allowMyLocation: false,
  });

  useCenteredMap(
    searchLocation,
    mapClassRef,
    mapClassRef.current?.getZoom() || INITIAL_MAP_ZOOM
  );

  useRadiusCircle(mapClassRef, radiusGroupRef, searchLocation, SEARCH_RADIUS);

  const [travelPass] = useTicketDetail<MobitTravelPass>(id);

  const [currentBike, currentBikeInfoRef] = useCurrentBike(
    MobitSelectedBikeIcon,
    currentBikeMarkersGroupRef,
    bikeCode || null
  );

  const [insideEfence] = useBikeInsideEfences(parkBikeCoords);

  const activeBike = currentBike || selectedBike;

  const [setRoutingMapGroup] = useRouting({
    platformRef,
    origin: searchLocation,
    destination: selectedBike,
    enabled: intent === "start" || intent === "resume",
    color: AppColors.MOBIT,
  });

  const [geoAddress, loadingGeoAddress, geoAddressError] = useReverseGeocode(
    platformRef,
    activeBike,
    intent === "start" || intent === "resume"
  );

  const [locationErrorClosed, setLocationErrorClosed] = useState(false);

  const [bikeAvailabilityError, setBikeAvailabilityError] =
    useState<ApiErrors | null>(null);

  const [endPauseRideError, setEndPauseRideError] = useState(false);

  const [pauseRideDialogOpened, setPauseRideDialogOpened] = useState(false);

  const [endRideDialogOpened, setEndRideDialogOpened] = useState(false);

  const [
    networkErrorDialogVisible,
    showNetworkErrorDialog,
    hideNetworkErrorDialog,
  ] = useBooleanState();

  const [openedUnlockPage, setOpenedUnlockPage] = useState<MobitUnlockPage>(
    MobitUnlockPage.NONE
  );

  const [reportBikeCode, setReportBikeCode] = useState("");

  const [nearbyBikes] = useNearbyBikes(
    intent === "start" ? searchLocation : null
  );

  // set search location based on user location
  useEffect(() => {
    if (searchLocation) {
      return;
    }

    if (userLocation) {
      setSearchLocation(userLocation);
    }
    // only on user location change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userLocation]);

  const selectedProvider: ProviderData | null = useMemo(
    () =>
      activeBike
        ? {
            id: activeBike.backCode,
            type: TravelPassProvider.mobit,
            name: TravelPassProvider.mobit,
            ...activeBike,
          }
        : null,
    [activeBike]
  );

  const selectBike = useCallback(async (bike: any) => {
    if (bike === null || currentBikeInfoRef.current) {
      return;
    }

    const data = bike as MobitNearbyBike & ProviderData;

    setSelectedBike(data);

    const response = await checkIsMobitBikeAvailable(
      data.backCode || data.frontCode,
      history
    );

    if (!response.error) {
      // ensure we still have same bike as request is async
      if (response.data.backCode === data.backCode) {
        const bikeNotAvailable = response.data.lockStatus === 0;

        bikeNotAvailable &&
          setBikeAvailabilityError(ApiErrors.MOBIT_LOCK_OPENED);

        setSelectedBike({
          ...data,
          errored: bikeNotAvailable,
        });
      } else {
        console.debug("Bike was changed, dropped controls update");
      }
    } else {
      setBikeAvailabilityError(response.error_code as ApiErrors);

      setSelectedBike({
        ...data,
        errored: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [setMapRefForMarkers] = useProviderDataMarkers(
    nearbyBikes,
    selectedProvider,
    selectBike
  );

  useEffect(() => {
    // make current bike selected so we show correct menu
    if (currentBike) {
      setSelectedBike({
        ...currentBike,
      });
    }
  }, [currentBike]);

  // show network error dialog
  useEffect(() => {
    if (locationSearchError || endPauseRideError || geoAddressError) {
      showNetworkErrorDialog();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationSearchError, endPauseRideError, geoAddressError]);

  // initialize map tap behavior
  useEffect(() => {
    const map = mapClassRef.current;
    if (!map || currentBikeInfoRef.current) {
      return;
    }

    const mapClicked = (event) => {
      if (selectedBike) {
        setSelectedBike(null);
      } else {
        setSearchLocation(
          map.screenToGeo(
            event.currentPointer.viewportX,
            event.currentPointer.viewportY
          )
        );
      }
    };
    map.addEventListener("tap", mapClicked);

    return () => {
      map.removeEventListener("tap", mapClicked);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapClassRef.current, currentBikeInfoRef.current, selectedProvider]);

  useEffect(() => {
    if (currentBike) {
      setIntent(currentBike.lockStatus ? "resume" : "pause");
    }
  }, [currentBike, intent]);

  useEffect(() => {
    const onMapViewChange = () => {
      const map = mapClassRef.current;

      const viewData = map?.getViewModel().getLookAtData();

      const zoomLevel = map?.getZoom();

      if (viewData?.bounds) {
        const boundingBox = viewData.bounds?.getBoundingBox();
        if (!boundingBox) {
          return;
        } // do not fetch fences and bikes when zoomed out
        const topLeft = boundingBox.getTopLeft();

        const bottomRight = boundingBox.getBottomRight();

        // do not fetch fences and bikes when zoomed out
        if (zoomLevel !== undefined && zoomLevel >= E_FENCES_MIN_ZOOM) {
          setBoundingBox({
            topLeft,
            bottomRight,
          });
        }
      }
    };

    const customizeMap = ({ map }: MapInitPayload) => {
      // note that vector renderer has opposite zIndex order as raster renderer!!!!
      currentBikeMarkersGroupRef.current = new H.map.Group({
        zIndex: 10,
        data: {},
        min: CURRENT_MARKER_MIN_ZOOM,
      });

      radiusGroupRef.current = new H.map.Group({ zIndex: 2, data: {} });

      map.addObject(radiusGroupRef.current!);

      map.addObject(currentBikeMarkersGroupRef.current);

      const userLocationGroup = new H.map.Group({ zIndex: 4, data: {} });

      setUserLocationGroup(userLocationGroup);

      map.addObject(userLocationGroup);

      const searchLocationGroup = new H.map.Group({ zIndex: 5, data: {} });

      map.addObject(searchLocationGroup);

      setSearchLocationMapReferences(
        map,
        searchLocationGroup,
        behaviourRef.current!
      );

      const routingGroup = new H.map.Group({ zIndex: 6, data: {} });

      setRoutingMapGroup(routingGroup);

      map.addObject(routingGroup);

      efencesGroupRef.current = new H.map.Group({
        zIndex: 2,
        data: {},
        min: E_FENCES_MIN_ZOOM,
      });

      map.addObject(efencesGroupRef.current);

      clusteringProviderRef.current = new H.clustering.Provider([], {
        min: 7,
        max: 23,
        clusteringOptions: {
          eps: 32,
          minWeight: 3,
        },
      });

      const clusteringLayer = new H.map.layer.ObjectLayer(
        clusteringProviderRef.current
      );

      map.addLayer(clusteringLayer);

      map
        .getViewPort()
        .setPadding(MAP_TOP_BOTTOM_PADDING, 0, MAP_TOP_BOTTOM_PADDING, 0);

      setMapRefForMarkers(map, clusteringProviderRef.current);

      map.addEventListener("mapviewchangeend", onMapViewChange);
    };

    onInit.then(customizeMap);

    const map = mapClassRef?.current;
    return () => {
      map?.removeEventListener("mapviewchangeend", onMapViewChange);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const centerMapToUserLocation = () => {
    if (userLocation) {
      mapClassRef.current?.setCenter(userLocation, true);

      mapClassRef.current?.setZoom(INITIAL_MAP_ZOOM, true);
    } else if (locationError === LocationError.PermissionDenied) {
      showLocationErrorDialog();
    }
  };

  const ringBike = () => {
    if (selectedBike) {
      const selectedBikeCode =
        selectedBike.backCode || selectedBike.frontCode || "";

      findBike(selectedBikeCode, history).then((response) => {
        if (!response.error) {
          // ?? What to show?
        } else {
          setBikeAvailabilityError(response.error_code as ApiErrors);
        }
      });
    }
  };

  const unlockBikeScanningCode = () =>
    setOpenedUnlockPage(MobitUnlockPage.SCAN);

  const unlockBikeEnteringCode = () =>
    setOpenedUnlockPage(MobitUnlockPage.ENTER);

  const reportBike = () => {
    let selectedBikeCode = "";

    // use only active bike if available
    if (bikeCode) {
      selectedBikeCode = bikeCode;
    } else if (selectedBike) {
      selectedBikeCode = selectedBike.backCode || selectedBike.frontCode || "";
    }

    selectedBikeCode && setReportBikeCode(selectedBikeCode);
  };

  const checkBikeIsInParkingZone = () => {
    currentBike &&
      setParkBikeCoords({
        lat: currentBike.lat,
        lng: currentBike.lng,
      });
  };

  const pauseBike = () => {
    checkBikeIsInParkingZone();
    setPauseRideDialogOpened(true);
  };

  const endRide = () => {
    checkBikeIsInParkingZone();
    setEndRideDialogOpened(true);
  };

  const pauseRideAfterLockClosed = async () => {
    try {
      setPausingRide(true);
      const response = await pauseRide(rideId, history);

      if (response.error) {
        console.warn("Failed to notify server on ride pause", response.error);

        setEndPauseRideError(true);
      } else {
        setIntent("unknown");
      }
    } catch (e) {
      console.warn("Failed to notify server on ride pause");

      setEndPauseRideError(true);
    } finally {
      setPausingRide(false);

      setPauseRideDialogOpened(false);
    }
  };

  const goToTicket = useCallback(
    () => history.push(Routes.MobitTicketDetail.replace(":id", id)),
    [history, id]
  );

  const endRideAfterLockClosed = async () => {
    try {
      setEndingRide(true);

      const response = await callEndRide(rideId, history);

      if (response.error) {
        console.warn("Failed to notify server on ride pause", response.error);

        setEndRideDialogOpened(false);

        setEndPauseRideError(true);
      } else {
        goToTicket();
      }
    } catch (e) {
      console.warn("Failed to notify server on ride pause");

      setEndRideDialogOpened(false);
    } finally {
      setEndingRide(false);
    }
  };

  const closePauseRideDialog = () => setPauseRideDialogOpened(false);

  const closeEndRideDialog = () => setEndRideDialogOpened(false);

  const closeLocationError = () => setLocationErrorClosed(true);

  const closeBikeAvailabilityError = () => setBikeAvailabilityError(null);

  const acknowledgeParkingZoneInfo = () =>
    dispatch(mobitParkingZonesAcknowledgeAction());

  const closeReportBikePage = () => setReportBikeCode("");

  const closeUnlockBikePage = () => setOpenedUnlockPage(MobitUnlockPage.NONE);

  const bottomSliderVisible = bikeCode || selectedBike;

  const closeNetworkErrorDialog = () => {
    setEndPauseRideError(false);
    hideNetworkErrorDialog();
  };

  return (
    <MapViewPage
      mapRef={mapRef}
      title={
        <>
          {t("mobit_ride.mobit_ride")}
          <MobitTutorial
            duration={travelPass?.duration || MOBIT_DEFAULT_DURATION}
          />
        </>
      }
      onNavBack={goToTicket}
      pageClassName="mobea-find-bike"
      className={`mobea__map-intent__${intent}`}
      headerHeight="compact"
      customCloseButton={null}
      mapActionsOffset={bottomSliderVisible ? (sliderOpen ? 200 : 60) : 0}
      css={{
        paddingTop: 24,
        "> .m_page__header": {
          padding: "0 24px",
          "> h1": {
            // align title with other subpages
            paddingRight: 20,
          },
        },
      }}
    >
      <div className="search-location-field-container">
        {renderLocationSearchField()}
      </div>

      <MapButtonGroup location="right">
        <LocateUserButton onClick={centerMapToUserLocation} />
      </MapButtonGroup>

      <BottomSlider
        title={t("mobit_ride.manage_ride")}
        defaultOpen={true}
        hidden={!bottomSliderVisible}
        onChange={(open) => setSliderOpen(open)}
        visibleHeight={70}
      >
        <MobitRideControls
          address={geoAddress?.label}
          addressLoading={loadingGeoAddress}
          bikeCode={selectedBike ? selectedBike.backCode : bikeCode}
          ringBike={ringBike}
          // prevent proceeding to unlocking when bike does not works or is used
          startRide={
            intent === "start" && !selectedBike?.errored
              ? unlockBikeScanningCode
              : undefined
          }
          resumeRide={intent === "resume" ? unlockBikeEnteringCode : undefined}
          pauseRide={intent === "pause" ? pauseBike : undefined}
          endRide={
            intent === "pause" || intent === "resume" ? endRide : undefined
          }
          reportBike={reportBike}
        />
      </BottomSlider>

      {!!locationError &&
        locationError !== LocationError.PermissionDenied &&
        !locationErrorClosed && (
          <MobeaModal
            type="error"
            confirmText={t("shared.ok")}
            title={t("shared.oops")}
            onConfirm={closeLocationError}
          >
            {t("mobit_ride.location_error_text")}
          </MobeaModal>
        )}

      {bikeAvailabilityError && (
        <MobitErrorDialogs
          errorCode={bikeAvailabilityError}
          onConfirm={closeBikeAvailabilityError}
        />
      )}

      {networkErrorDialogVisible && (
        <MobeaModal
          type="error"
          confirmText={t("shared.ok")}
          title={t("shared.oops")}
          onConfirm={closeNetworkErrorDialog}
        >
          {t("shared.network_error_text")}
        </MobeaModal>
      )}

      {currentBike && pauseRideDialogOpened && (
        <PauseRideDialog
          lockStatus={currentBike?.lockStatus}
          loading={insideEfence === null}
          pausingInProgress={pausingRide}
          version={insideEfence ? "inside" : "outside"}
          onConfirm={pauseRideAfterLockClosed}
          onClose={closePauseRideDialog}
        />
      )}

      {currentBike && endRideDialogOpened && (
        <EndRideDialog
          lockStatus={currentBike?.lockStatus}
          loading={insideEfence === null}
          endInProgress={endingRide}
          expiration={travelPass ? travelPass.expiration : 0}
          version={insideEfence ? "inside" : "outside"}
          onConfirm={endRideAfterLockClosed}
          onClose={closeEndRideDialog}
        />
      )}

      {locationErrorDialogVisible && (
        <LocationAccessDeniedDialog
          hide={hideLocationErrorDialog}
          showClickPinMessage={intent === "start"}
        />
      )}

      {
        // show only when ride is in progress - we park bike
        bikeCode && !mobitParkingZonesAcknowledged && (
          <ParkingZoneModal onConfirm={acknowledgeParkingZoneInfo} />
        )
      }

      {reportBikeCode && (
        <MobitReportBikePage id={reportBikeCode} goBack={closeReportBikePage} />
      )}

      {openedUnlockPage === MobitUnlockPage.SCAN && (
        <ScanCodePage
          goToEnterCode={unlockBikeEnteringCode}
          goBack={closeUnlockBikePage}
          goToTicket={goToTicket}
        />
      )}

      {openedUnlockPage === MobitUnlockPage.ENTER && (
        <MobitUnlockBikePage
          bikeCode={bikeCode}
          goToScanCode={unlockBikeScanningCode}
          goBack={closeUnlockBikePage}
          goToTicket={goToTicket}
        />
      )}
      <MapOfflineOverlay shadow />
    </MapViewPage>
  );
}
