import { LocationCursorIcon } from "icons/navigation";
import { ReactElement, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
  RoutePlanTrip,
  RoutePlanTripSegment,
  SegmentType,
} from "services/mapService";
import { formatTimeWithLocale } from "utils";
import { AppColors } from "utils/colors";
import { TripSegmentDetail } from "./TripSegmentDetail";
import { TripSegmentTemplate } from "./TripSegmentTemplate";

const H = window.H;

interface TripDetailsProps {
  trip: RoutePlanTrip;
  onTripChange: (trip: RoutePlanTrip) => void;
}

export function TripDetails({
  trip,
  onTripChange,
}: TripDetailsProps): ReactElement {
  const { t } = useTranslation();

  const { segments } = trip;

  const language = useSelector((state) => state.user.language);

  const lastSegment = segments[segments.length - 1];

  useEffect(() => {
    const segmentsWithoutWaypoints = trip.segments.filter(
      (segment) =>
        segment.waypoints.length === 0 &&
        (segment.bikeRoute || []).length === 0 &&
        segment.type === SegmentType.Walk
    );

    if (segmentsWithoutWaypoints.length === 0) {
      return;
    }

    const promises = segmentsWithoutWaypoints.map((segment) => {
      const { from, to } = segment;

      if (!from || !to) {
        console.warn(
          "Missing from or to for the segment so cannot get the route",
          segment
        );
        return;
      }

      const platform = new H.service.Platform({
        apikey: process.env.HERE_API_KEY,
      });

      // @ts-ignore
      const service = platform.getRoutingService(null, 8);

      return new Promise<RoutePlanTripSegment>((resolve, reject) => {
        service.calculateRoute(
          {
            routingMode: "fast",
            transportMode: "pedestrian",
            origin: `${from.lat},${from.lng}`,
            destination: `${to.lat},${to.lng}`,
            return: "polyline,summary",
          },
          (result: any) => {
            if (result?.routes?.length) {
              const route = result.routes[0];

              const updatedSegment: RoutePlanTripSegment = {
                ...segment,
                distance: route.sections.reduce(
                  (acc, section) => acc + section.summary?.length || 0,
                  0
                ),
                bikeRoute: route.sections.map((section) => section.polyline),
              };

              resolve(updatedSegment);
            }
            reject("No route data");
          },
          (error) => {
            console.warn("Failed to load route data for the segment", error);
            reject("Failed to load route data for the segment");
          }
        );
      });
    });

    Promise.all(promises)
      .then((updates) => {
        const patchedSegments = trip.segments
          .map((segment) => {
            const seg = updates.find((s) => s?.id === segment.id);

            if (seg) {
              // drop short segments created by imprecise distance calculation
              if (!seg.distance) {
                return {
                  ...segment,
                  waypoints: [segment.from, segment.to],
                };
              }

              // patch times based on real data for start / end segments
              return {
                ...seg,
              };
            }
            return segment;
          })
          .filter((segment) => !!segment) as RoutePlanTripSegment[];

        onTripChange({
          ...trip,
          segments: patchedSegments,
        });
      })
      .catch(() => {
        // What can we do here?
      });
  }, [onTripChange, trip]);

  return (
    <div
      className="route-planner-trip-details"
      css={{
        margin: "0 -24px",
      }}
    >
      <div
        className="route-planner-trip-details__segment-container"
        css={{
          overflowY: "auto",
          padding: "0 24px",
          maxHeight: window.innerHeight - 130,
        }}
      >
        {segments.map((segment: RoutePlanTripSegment, index, array) => (
          <TripSegmentDetail
            key={segment.id}
            {...segment}
            nextSegment={array[index + 1]}
          />
        ))}

        <TripSegmentTemplate
          icon={
            <div
              css={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                border: `1px solid ${AppColors.GRAY_200}`,
                width: 32,
                height: 32,
                borderRadius: "50%",
                marginTop: 4,
                color: AppColors.GRAY_300,
              }}
            >
              <LocationCursorIcon fill="currentColor" width={16} height={16} />
            </div>
          }
          title={lastSegment.to?.address}
          time={formatTimeWithLocale(lastSegment.endTime, language)}
          description={t("map.reached_destination")}
        />
      </div>
    </div>
  );
}
