import { isSameLocation } from "maps";
import { UserLocationIcon } from "maps/icons";
import { useEffect, useMemo, useRef } from "react";
import { LocationCoords, LocationSearchPlace } from "services/mapService";

export function useRoutePlannerSource(
  routeSource: LocationSearchPlace | null,
  userLocation: LocationCoords | null
) {
  const routeSourceDestinationGroupRef = useRef<H.map.Group | null>(null);

  const groupObjects = useRef<H.map.Object[]>([]);

  const locationCursorIcon = useMemo(
    () =>
      new H.map.Icon(UserLocationIcon("#fff", "#000"), {
        anchor: new H.math.Point(15, 15),
        crossOrigin: "anonymous",
        hitArea: new H.map.HitArea(H.map.HitArea.ShapeType.NONE),
      }),
    []
  );

  const setGroupRef = (ref: H.map.Group) =>
    (routeSourceDestinationGroupRef.current = ref);

  useEffect(() => {
    if (!routeSourceDestinationGroupRef.current) {
      return;
    }

    routeSourceDestinationGroupRef.current.removeObjects(groupObjects.current);

    groupObjects.current = [];

    if (!routeSource || isSameLocation(routeSource, userLocation)) {
      return;
    }

    const locationMarker = new H.map.Marker(routeSource, {
      icon: locationCursorIcon,
      data: {},
    });

    const addedObject =
      routeSourceDestinationGroupRef.current.addObject(locationMarker);

    groupObjects.current.push(addedObject);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    locationCursorIcon,
    routeSource,
    userLocation,
    routeSourceDestinationGroupRef.current,
  ]);

  return [setGroupRef];
}
