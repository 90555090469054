import { useBudget, useCustomerData } from "common/hooks";
import { useAppName } from "common/hooks/useAppName";
import { Spinner } from "common/Spinner";
import { Tutorial } from "common/tutorial/Tutorial";
import { ReactElement, useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { tutorialFinishAction } from "state/actions/OnboardingActions";
import { formatDateWithLocale, Routes } from "utils";
import { AppColors } from "utils/colors";
import { getConfig } from "utils/configure";
import { FirstSlideImage, SecondSlideImage, ThirdSlideImage } from "./slides";
import "./TutorialPage.scss";

export function TutorialPage(): ReactElement {
  const history = useHistory();

  const dispatch = useDispatch();

  const { t } = useTranslation();

  const language = useSelector((state) => state.user.language);

  const startDate = useSelector((state) => state.user.startDate || 0);

  const endDate = useSelector((state) => state.user.endDate || 0);

  const { clientName } = getConfig();

  const loading = useCustomerData();

  const appName = useAppName();

  const { state } = useLocation();
  const [, reloadBudget] = useBudget();

  const done = () => {
    dispatch(tutorialFinishAction());

    if (typeof state === "object" && state?.["from"] === Routes.Tutorials) {
      history.goBack();
    } else {
      history.push(Routes.Home);
    }
  };

  const dateFormat: Intl.DateTimeFormatOptions = {
    day: "numeric",
    month: "long",
    year: "numeric",
  } as Intl.DateTimeFormatOptions;

  const start = formatDateWithLocale(startDate, language, dateFormat);

  const end = formatDateWithLocale(endDate, language, dateFormat);

  useEffect(() => {
    reloadBudget();
  }, [reloadBudget]);

  return (
    <main className="mobea-tutorial">
      {loading ? (
        <Spinner />
      ) : (
        <Tutorial
          steps={[
            {
              headline: t("tutorial.first_headline", {
                appName,
                clientName,
              }),
              image: (
                <FirstSlideImage
                  color={AppColors.PRIMARY}
                  light={AppColors.PRIMARY_LIGHT}
                />
              ),
              info: t("tutorial.first_text", {
                appName,
                clientName,
              }),
            },
            {
              headline: t("tutorial.second_headline"),
              image: (
                <SecondSlideImage
                  color={AppColors.PRIMARY}
                  light={AppColors.PRIMARY_LIGHT}
                />
              ),
              info: (
                <>
                  <p>
                    <Trans i18nKey="tutorial.second_text_1">
                      You can access the {{ appName }}
                      {" app from the "}
                      <span className="mobea__bold mobea__no-wrap mobea__arial">
                        {{ start }}
                      </span>
                      {" until the "}
                      <span className="mobea__bold mobea__no-wrap  mobea__arial">
                        {{ end }}
                      </span>
                      .
                    </Trans>
                  </p>
                  <p>{t("tutorial.second_text_2")}</p>
                </>
              ),
            },
            {
              headline: t("tutorial.third_headline"),
              image: (
                <ThirdSlideImage
                  color={AppColors.PRIMARY}
                  light={AppColors.PRIMARY_LIGHT}
                />
              ),
              info: (
                <>
                  <p>{t("tutorial.third_text_1")}</p>
                  <p>{t("tutorial.third_text_2")}</p>
                </>
              ),
            },
          ]}
          permanentSkip
          endButtonText={t("tutorial.start")}
          onClose={done}
        />
      )}
    </main>
  );
}
